import React, { useState, useEffect } from 'react';
import Header from 'apps/broker/components/Header';
import SidemenuRh from 'apps/broker/components/Sidebar';
import Main from 'apps/broker/components/MainContent';
import ModalDelete from 'components/Modal';
import { Wrapper, Content, Consult, ModalRemoveBeneficiary } from './styles';
import Filters from './components/Filters';
import services from 'apps/broker/services';
import TableResult from './components';
import AddBeneficiary from './components/AddBeneficiary';

let beneficiaryToDelete;
let nameBeneficiaryToDelete;

const BeneficiariosCorretor = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [masterCheckboxChecked, setMasterCheckboxChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [beneficiarios, setBeneficiarios] = useState([]);
  const [beneficiariosFilter, setBeneficiariosFilter] = useState([]);
  const [allEstipulantes, setAllEstipulantes] = useState([]);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
  const [message, setMessage] = useState(null);
  const [messageSuccess, setMessageSuccess] = useState(null);
  const [addFilters, setAddFilters] = useState({
    nomeBeneficiario: '',
    status: 'Ativo'
  });
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [allEstipulanteAndSubs, setAllEstipulanteAndSubs] = useState([]);
  const [estipulanteAndSubsSelected, setEstipulanteAndSubsSelected] = useState(
    []
  );
  const [estipulantePlanoSelected, setEstipulantePlanoSelected] = useState([]);
  const [estipulanteSelected, setEstipulanteSelected] = useState();
  const [selectedEstipulante, setSelectedEstipulante] = useState();

  const getBeneficiarios = async (estipulante_id) => {
    const company = allEstipulantes.filter(
      (item) => item.id === estipulante_id
    );

    setSelectedEstipulante(company.razao_social);
    setLoading(true);

    const response =
      await services.beneficiaries.getSubCompaniesAndBeneficiariesByCompanyId(
        estipulante_id
      );

    if (response.status === 200) {
      setLoading(false);
      const allBeneficiaries = response.data;
      setBeneficiarios(allBeneficiaries);

      setAddFilters((prevState) => ({
        ...prevState,
        nomeBeneficiario: ''
      }));

      const beneciariesFiltered = allBeneficiaries.filter((item) => item.status === addFilters['status']);
      setBeneficiariosFilter(beneciariesFiltered);
    }

    // api
    //   .get('/corretor/estipulante-sub/beneficiarios', {
    //     headers: {
    //       id: estipulante_id,
    //       authorization: `Bearer ${user.token}`
    //     }
    //   })
    //   .then((resp) => {
    //     const { beneficiarios, subEstipulantes } = resp.data;
    //     let allBeneficiarios = [...beneficiarios];
    //     if (subEstipulantes.length) {
    //       subEstipulantes.forEach((subEstipulante) => {
    //         if (subEstipulante.beneficiarios.length) {
    //           allBeneficiarios = [
    //             ...allBeneficiarios,
    //             ...subEstipulante.beneficiarios
    //           ];
    //         }
    //       });
    //     }

    //     setBeneficiarios(allBeneficiarios);
    //     setAddFilters((prevState) => ({
    //       ...prevState,
    //       nomeBeneficiario: ''
    //     }));
    //     setBeneficiariosFilter(
    //       allBeneficiarios.filter(
    //         (item) => item.status === addFilters['status']
    //       )
    //     );
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
    //   .finally(() => {
    //     setLoading(false);
    //   });
  };

  const ContentModalRemoveBeneficiary = () => (
    <ModalRemoveBeneficiary>
      <h1>
        O usuário <span>{nameBeneficiaryToDelete}</span> será removido do plano.{' '}
      </h1>

      <p className={message ? 'text-error' : messageSuccess && 'text-success'}>
        {message ? message : messageSuccess ? messageSuccess : null}
      </p>
      <div>
        <button onClick={sendInfosToDeleteBeneficiary}>Remover</button>
        <button onClick={handleCloseModal}>Cancelar</button>
      </div>
    </ModalRemoveBeneficiary>
  );

  async function sendInfosToDeleteBeneficiary() {
    const body = {
      beneficiarioId: beneficiaryToDelete
    };

    const response = await services.moviment.createExclusionByBeneficiaryId(
      body
    );

    if (response.data.status === 204) {
      setMessage(
        'Já existe uma movimentação de Exclusão para esse Beneficiário.'
      );
    } else {
      setMessageSuccess('Pedido de exclusão feito com sucesso!');
    }

    setTimeout(() => {
      setIsOpenModalDelete(false);
    }, 1000);
  }

  function handleCloseModal() {
    setIsOpenModalDelete(false);
  }

  useEffect(() => {
    if (allEstipulantes.length > 0) {
      setSelectedEstipulante(allEstipulantes[0].razao_social);

      getBeneficiarios(allEstipulantes[0].id);
    }
  }, [allEstipulantes]);

  return (
    <Wrapper>
      <SidemenuRh />
      <Content>
        <Header />
        <Main>
          <div className="main-painel">
            <Consult>
              <div className="title">
                <h1>Consulta Beneficiários</h1>
                <AddBeneficiary allEstipulantes={allEstipulantes} />
              </div>
            </Consult>
            <Filters
              setMasterCheckboxChecked={setMasterCheckboxChecked}
              setSelectedCheckboxes={setSelectedCheckboxes}
              allEstipulantes={allEstipulantes}
              setSelectedEstipulante={setSelectedEstipulante}
              selectedEstipulante={selectedEstipulante}
              setAddFilters={setAddFilters}
              addFilters={addFilters}
              estipulanteSelected={estipulanteSelected}
              setEstipulanteAndSubsSelected={setEstipulanteAndSubsSelected}
              setAllEstipulanteAndSubs={setAllEstipulanteAndSubs}
              setCurrentPage={setCurrentPage}
              getBeneficiarios={getBeneficiarios}
              setEstipulanteSelected={setEstipulanteSelected}
              setEstipulantePlanoSelected={setEstipulantePlanoSelected}
              estipulantePlanoSelected={estipulantePlanoSelected}
              estipulanteAndSubsSelected={estipulanteAndSubsSelected}
              allEstipulanteAndSubs={allEstipulanteAndSubs}
              beneficiariosFilter={beneficiariosFilter}
            />

            <TableResult
              {...{
                loading,
                estipulanteAndSubsSelected,
                estipulanteSelected,
                addFilters,
                setAddFilters,
                beneficiarios,
                setAllEstipulantes,
                estipulantePlanoSelected,
                setBeneficiariosFilter,
                beneficiariosFilter,
                getBeneficiarios,
                setLoading,
                setEstipulantePlanoSelected,
                setSelectedCheckboxes,
                selectedCheckboxes,
                setBeneficiarios,
                currentPage,
                setCurrentPage,
                setMasterCheckboxChecked,
                masterCheckboxChecked,
                setEstipulanteSelected,
                setEstipulanteAndSubsSelected,
                setAllEstipulanteAndSubs
              }}
            />
          </div>
        </Main>
        <div id={loading ? 'sentinela-loading' : 'sentinela'}></div>
      </Content>

      <ModalDelete
        openModal={isOpenModalDelete}
        setOpenModal={handleCloseModal}
      >
        <ContentModalRemoveBeneficiary />
      </ModalDelete>
    </Wrapper>
  );
};

export default BeneficiariosCorretor;
