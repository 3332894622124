import { useState, useEffect } from 'react';
import { SearchBeneficiarios } from './styles';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from 'react-icons/io';
import { Checkbox, Radio, ThemeProvider, createTheme } from '@mui/material';
import DownloadPlansInformation from '../DownloadPlansInformation';

const theme = createTheme({
  palette: {
    genoaBlue: {
      main: '#456AED'
    },
    darkGenoaBlue: {
      main: '#1E2552'
    }
  }
});

const Filters = ({
  setMasterCheckboxChecked,
  setSelectedCheckboxes,
  allEstipulantes,
  selectedEstipulante,
  setAddFilters,
  addFilters,
  estipulanteSelected,
  setEstipulanteAndSubsSelected,
  setAllEstipulanteAndSubs,
  setCurrentPage,
  getBeneficiarios,
  setEstipulanteSelected,
  setEstipulantePlanoSelected,
  estipulantePlanoSelected,
  estipulanteAndSubsSelected,
  allEstipulanteAndSubs,
  beneficiariosFilter
}) => {
  const [carousselPageBroker, setCarousselPageBroker] = useState(0);
  const [carousselPageContract, setCarousselPageContract] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);

  const changeStatusClickHandler = ({ target }) => {
    setAddFilters((prevState) => ({
      ...prevState,
      status: target.value
    }));
  };

  const handleAllFiltersSearch = ({ target }) => {
    if (target.name === 'nomeBeneficiario' || target.name === 'status') {
      setAddFilters({
        ...addFilters,
        [target.name]: target.value
      });
    }
  };

  const carousselLeftArrowClickHandler = (typeFilter) => {
    if (typeFilter === 'broker') {
      setCarousselPageBroker((prevState) => {
        if (prevState > 0) {
          return prevState - 1;
        }
        return estipulanteSelected.subEstipulantes.length;
      });
    } else if (typeFilter === 'contract') {
      setCarousselPageContract((prevState) => {
        if (prevState > 0) {
          return prevState - 1;
        }
        return estipulanteSelected.contratos.length;
      });
    }
  };

  const estipulanteAndSubsCheckboxClickHandler = ({ target }) => {
    if (target.value === 'todos' && target.checked) {
      let estipAndSubsSelected = [estipulanteSelected.id];

      estipulanteSelected.subEstipulantes.forEach((sub) => {
        estipAndSubsSelected = [...estipAndSubsSelected, sub.id];
      });

      return setEstipulanteAndSubsSelected(estipAndSubsSelected);
    }

    if (target.value === 'todos' && !target.checked) {
      return setEstipulanteAndSubsSelected([]);
    }

    if (target.checked) {
      setEstipulanteAndSubsSelected((prevState) => {
        const newState = [...prevState, Number(target.value)];
        newState.sort((a, b) => a - b);

        return newState;
      });
    }

    if (!target.checked) {
      setEstipulanteAndSubsSelected((prevState) => {
        const filter = prevState.filter(
          (elementId) => elementId !== Number(target.value)
        );
        return filter;
      });
    }
  };

  const carousselRightArrowClickHandler = (typeFilter) => {
    if (typeFilter === 'broker') {
      setCarousselPageBroker((prevState) => {
        if (prevState >= estipulanteSelected.subEstipulantes.length) {
          return 0;
        }
        return prevState + 1;
      });
    } else if (typeFilter === 'contract') {
      setCarousselPageContract((prevState) => {
        if (prevState >= estipulanteSelected.contratos.length) {
          return 0;
        }
        return prevState + 1;
      });
    }
  };

  const renderEstipulanteAndSubsCarousselOptions = () => {
    if (estipulanteSelected && estipulanteSelected.subEstipulantes) {
      if (carousselPageBroker === 0) {
        return (
          <>
            <div className="caroussel-item">
              <Checkbox
                size="small"
                value={estipulanteSelected.id}
                color={'darkGenoaBlue'}
                icon={<IoMdRadioButtonOff size={20} />}
                checkedIcon={<IoMdRadioButtonOn size={20} />}
                checked={estipulanteAndSubsSelected.some((checkboxName) => {
                  return checkboxName === Number(estipulanteSelected.id);
                })}
                onChange={estipulanteAndSubsCheckboxClickHandler}
              />
              <label>{estipulanteSelected.razao_social}</label>
            </div>
            {estipulanteSelected.subEstipulantes.map((item) => (
              <div className="caroussel-item">
                <Checkbox
                  size="small"
                  value={item.id}
                  color={'darkGenoaBlue'}
                  icon={<IoMdRadioButtonOff size={20} />}
                  checkedIcon={<IoMdRadioButtonOn size={20} />}
                  checked={estipulanteAndSubsSelected.some((checkboxName) => {
                    return checkboxName === Number(item.id);
                  })}
                  onChange={estipulanteAndSubsCheckboxClickHandler}
                />
                <label>{item.razao_social}</label>
              </div>
            ))}
          </>
        );
      }
      const options = [
        ...[estipulanteSelected],
        ...estipulanteSelected.subEstipulantes
      ];
      return (
        <>
          {options.map((item, index) => {
            if (index >= carousselPageBroker) {
              return (
                <div className="caroussel-item">
                  <Checkbox
                    size="small"
                    value={item.id}
                    color={'darkGenoaBlue'}
                    icon={<IoMdRadioButtonOff size={20} />}
                    checkedIcon={<IoMdRadioButtonOn size={20} />}
                    checked={estipulanteAndSubsSelected.some((checkboxName) => {
                      return checkboxName === Number(item.id);
                    })}
                    onChange={estipulanteAndSubsCheckboxClickHandler}
                  />
                  <label>{item.razao_social}</label>
                </div>
              );
            }
            return '';
          })}
          {options.map((item, index) => {
            if (index < carousselPageBroker) {
              return (
                <div className="caroussel-item">
                  <Checkbox
                    size="small"
                    value={item.id}
                    color={'darkGenoaBlue'}
                    icon={<IoMdRadioButtonOff size={20} />}
                    checkedIcon={<IoMdRadioButtonOn size={20} />}
                    checked={estipulanteAndSubsSelected.some((checkboxName) => {
                      return checkboxName === Number(item.id);
                    })}
                    onChange={estipulanteAndSubsCheckboxClickHandler}
                  />
                  <label>{item.razao_social}</label>
                </div>
              );
            }
            return '';
          })}
        </>
      );
    }
  };

  const handlerCarrouselContracts = () => {
    return (
      <>
        {estipulanteSelected.contratos.map((item, index) => {
          if (index >= carousselPageContract) {
            return (
              <div className="caroussel-item">
                <Checkbox
                  size="small"
                  value={item.contrato_id}
                  icon={<IoMdRadioButtonOff size={20} />}
                  checkedIcon={<IoMdRadioButtonOn size={20} />}
                  color={'darkGenoaBlue'}
                  checked={estipulantePlanoSelected.some((checkboxName) => {
                    return checkboxName === Number(item.contrato_id);
                  })}
                  onChange={estipulantePlanosCheckboxClickHandler}
                />
                {item?.numero_apolice ? (
                  <label className="produto-seguradora-nome-label">
                    {`${item.produto_seguradora_nome} (${item?.numero_apolice})`}
                  </label>
                ) : (
                  <label className="produto-seguradora-nome-label">
                    {item.produto_seguradora_nome}
                  </label>
                )}
              </div>
            );
          }
          return '';
        })}

        {estipulanteSelected.contratos.map((item, index) => {
          if (index < carousselPageContract) {
            return (
              <div className="caroussel-item">
                <Checkbox
                  size="small"
                  value={item.contrato_id}
                  icon={<IoMdRadioButtonOff size={20} />}
                  checkedIcon={<IoMdRadioButtonOn size={20} />}
                  color={'darkGenoaBlue'}
                  checked={estipulantePlanoSelected.some((checkboxName) => {
                    return checkboxName === Number(item.contrato_id);
                  })}
                  onChange={estipulantePlanosCheckboxClickHandler}
                />
                {item?.numero_apolice ? (
                  <label className="produto-seguradora-nome-label">
                    {`${item.produto_seguradora_nome} (${item?.numero_apolice})`}
                  </label>
                ) : (
                  <label className="produto-seguradora-nome-label">
                    {item.produto_seguradora_nome}
                  </label>
                )}
              </div>
            );
          }
          return '';
        })}
      </>
    );
  };

  const selectEstipulanteClickHandler = async ({ target }) => {
    setCarousselPageBroker(0);
    setMasterCheckboxChecked(false);
    setSelectedCheckboxes([]);
    const [selectedEstipulante] = allEstipulantes.filter(
      (estipulante) => estipulante.id === Number(target.value)
    );

    let estipAndSubsSelected = [selectedEstipulante.id];

    selectedEstipulante.subEstipulantes.forEach((sub) => {
      estipAndSubsSelected = [...estipAndSubsSelected, sub.id];
    });

    setEstipulanteAndSubsSelected(estipAndSubsSelected);
    setAllEstipulanteAndSubs(estipAndSubsSelected);
    setCurrentPage(1);
    await getBeneficiarios(target.value);
    setEstipulanteSelected(selectedEstipulante);

    const allPlanosSelected = selectedEstipulante.contratos.map(
      (item) => item.contrato_id
    );

    setEstipulantePlanoSelected(allPlanosSelected);
  };

  const estipulantePlanosCheckboxClickHandler = ({ target }) => {
    if (target.value === 'todos' && target.checked) {
      let estipPlanosSelected = estipulanteSelected.contratos.map(
        (item) => item.contrato_id
      );
      return setEstipulantePlanoSelected(estipPlanosSelected);
    }

    if (target.value === 'todos' && !target.checked) {
      return setEstipulantePlanoSelected([]);
    }

    if (target.checked) {
      return setEstipulantePlanoSelected((prevState) => {
        const newState = [...prevState, Number(target.value)];
        newState.sort((a, b) => a - b);

        return newState;
      });
    }

    if (!target.checked) {
      return setEstipulantePlanoSelected((prevState) => {
        const filter = prevState.filter(
          (elementId) => elementId !== Number(target.value)
        );
        return filter;
      });
    }
  };

  const renderContratoPlanosOptions = () => {
    if (
      estipulanteSelected &&
      estipulanteSelected.contratos &&
      estipulanteSelected.contratos.length > 0
    ) {
      return (
        <div className="estipulantes-multiple-checkbox-row">
          <ThemeProvider theme={theme}>
            <div className="checkbox-container">
              <Checkbox
                size="small"
                value={'todos'}
                icon={<IoMdRadioButtonOff size={20} />}
                checkedIcon={<IoMdRadioButtonOn size={20} />}
                IoMdRadioButtonOffIcon={<IoMdRadioButtonOff size={20} />}
                color={'darkGenoaBlue'}
                IoMdRadioButtonOff={
                  estipulanteSelected.contratos &&
                  estipulantePlanoSelected.length &&
                  estipulanteSelected.contratos.length !==
                    estipulantePlanoSelected.length
                }
                checked={
                  estipulanteSelected.contratos.length ===
                  estipulantePlanoSelected.length
                }
                onClick={estipulantePlanosCheckboxClickHandler}
              />
              <label className="produto-seguradora-nome-label">Todos</label>
            </div>
            <div
              className="caroussel-container"
              style={{
                width: `${width - 855}px`
              }}
            >
              <RiArrowLeftSLine
                size={13}
                onClick={() => carousselLeftArrowClickHandler('contract')}
              />
              <div className="caroussel-items-column">
                {handlerCarrouselContracts()}
              </div>
              <RiArrowRightSLine
                size={13}
                onClick={() => carousselRightArrowClickHandler('contract')}
              />
            </div>
          </ThemeProvider>
        </div>
      );
    }
  };

  const renderEstipulanteAndSubOptions = () => {
    let contagemDeLetra = 5;
    contagemDeLetra += estipulanteSelected
      ? estipulanteSelected.razao_social.length
      : 0;

    if (
      estipulanteSelected &&
      estipulanteSelected.subEstipulantes &&
      estipulanteSelected.subEstipulantes.length
    ) {
      estipulanteSelected.subEstipulantes.map((item) => {
        contagemDeLetra += item.razao_social.length;
        return item;
      });
    }
    if (
      contagemDeLetra <
        (width / 1535) * 103 -
          (1 + estipulanteSelected?.subEstipulantes?.length) * 3 &&
      estipulanteSelected
    ) {
      return (
        <>
          <ThemeProvider theme={theme}>
            <div className="checkbox-container">
              <Checkbox
                size="small"
                value={'todos'}
                icon={<IoMdRadioButtonOff size={20} />}
                checkedIcon={<IoMdRadioButtonOn size={20} />}
                IoMdRadioButtonOffIcon={<IoMdRadioButtonOff size={20} />}
                color={'darkGenoaBlue'}
                IoMdRadioButtonOff={
                  estipulanteAndSubsSelected.length &&
                  estipulanteAndSubsSelected.length !==
                    allEstipulanteAndSubs.length
                }
                checked={
                  estipulanteAndSubsSelected.length ===
                  allEstipulanteAndSubs.length
                }
                onClick={estipulanteAndSubsCheckboxClickHandler}
              />
              <label>Todos</label>
            </div>
            <div>
              <Checkbox
                size="small"
                value={estipulanteSelected.id}
                color={'darkGenoaBlue'}
                icon={<IoMdRadioButtonOff size={20} />}
                checkedIcon={<IoMdRadioButtonOn size={20} />}
                checked={estipulanteAndSubsSelected.some((checkboxName) => {
                  return checkboxName === Number(estipulanteSelected.id);
                })}
                onChange={estipulanteAndSubsCheckboxClickHandler}
              />
              <label>{estipulanteSelected.razao_social}</label>
            </div>
            {estipulanteSelected.subEstipulantes &&
            estipulanteSelected.subEstipulantes.length
              ? estipulanteSelected.subEstipulantes.map((item) => {
                  return (
                    <div>
                      <Checkbox
                        size="small"
                        value={item.id}
                        color={'darkGenoaBlue'}
                        icon={<IoMdRadioButtonOff size={20} />}
                        checkedIcon={<IoMdRadioButtonOn size={20} />}
                        checked={estipulanteAndSubsSelected.some(
                          (checkboxName) => {
                            return checkboxName === Number(item.id);
                          }
                        )}
                        onChange={estipulanteAndSubsCheckboxClickHandler}
                      />
                      <label>{item.razao_social}</label>
                    </div>
                  );
                })
              : ''}
          </ThemeProvider>
        </>
      );
    }
    if (
      estipulanteSelected &&
      estipulanteSelected.subEstipulantes &&
      estipulanteSelected.subEstipulantes.length
    ) {
      return (
        <>
          <ThemeProvider theme={theme}>
            <div className="checkbox-container">
              <Checkbox
                size="small"
                value={'todos'}
                color={'darkGenoaBlue'}
                icon={<IoMdRadioButtonOff size={20} />}
                checkedIcon={<IoMdRadioButtonOn size={20} />}
                IoMdRadioButtonOffIcon={<IoMdRadioButtonOff size={20} />}
                IoMdRadioButtonOff={
                  estipulanteAndSubsSelected.length &&
                  estipulanteAndSubsSelected.length !==
                    allEstipulanteAndSubs.length
                }
                checked={
                  estipulanteAndSubsSelected.length ===
                  allEstipulanteAndSubs.length
                }
                onClick={estipulanteAndSubsCheckboxClickHandler}
              />
              <label>Todos</label>
            </div>
          </ThemeProvider>
          <div
            className="caroussel-container"
            style={{
              width: `${width - 855}px`
            }}
          >
            <RiArrowLeftSLine
              size={13}
              onClick={() => carousselLeftArrowClickHandler('broker')}
            />
            <div className="caroussel-items-column">
              <ThemeProvider theme={theme}>
                {renderEstipulanteAndSubsCarousselOptions()}
              </ThemeProvider>
            </div>
            <RiArrowRightSLine
              size={13}
              onClick={() => carousselRightArrowClickHandler('broker')}
            />
          </div>
        </>
      );
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
  });

  return (
    <SearchBeneficiarios>
      <div className="first-row-container">
        <div className="selectbox">
          <label>Consultar por empresa</label>
          <div>
            <select
              value={selectedEstipulante}
              name="cars"
              id="cars"
              onChange={selectEstipulanteClickHandler}
            >
              <option value="" disabled>
                Selecione
              </option>
              {allEstipulantes &&
                allEstipulantes?.map((empresa, index) => {
                  return (
                    <option value={empresa.id}>{empresa.razao_social}</option>
                  );
                })}
            </select>
          </div>
          <div className="inputSearch">
            <div className="search">
              <label>Consultar por nome</label>
              <div className="input-button">
                <input
                  type="text"
                  value={addFilters.nomeBeneficiario}
                  name="nomeBeneficiario"
                  placeholder="Pesquisar Beneficiário..."
                  onChange={(e) => handleAllFiltersSearch(e)}
                ></input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="selectbox">
        <div
          className="selectbox-first-row-container"
          style={{
            gridTemplateColumns: `${width - 775}px 210px`
          }}
        >
          <div>
            <label className="title-label">Estipulantes</label>
            <div className="estipulantes-multiple-checkbox-row">
              {renderEstipulanteAndSubOptions()}
            </div>
          </div>
          <div>
            <label id="status-label">Status</label>
            <ThemeProvider theme={theme}>
              <div className="status-options-container">
                <div className="checkbox-container">
                  <div
                    className="radio-input-container"
                    onClick={changeStatusClickHandler}
                  >
                    <Radio
                      size="small"
                      className="radio-input"
                      value={'Todos'}
                      color={'darkGenoaBlue'}
                      checked={addFilters['status'] === 'Todos'}
                      // checked={showTitulares}
                    />
                    <label>Todos</label>
                  </div>
                </div>
                <div className="checkbox-container">
                  <div
                    className="radio-input-container"
                    onClick={changeStatusClickHandler}
                    // onClick={showTitularesClickHandler}
                  >
                    <Radio
                      size="small"
                      className="radio-input"
                      value={'Ativo'}
                      color={'darkGenoaBlue'}
                      checked={addFilters['status'] === 'Ativo'}
                      // checked={showTitulares}
                    />
                    <label>Ativo</label>
                  </div>
                </div>
                <div className="checkbox-container">
                  <div
                    className="radio-input-container"
                    onClick={changeStatusClickHandler}
                    // onClick={showTitularesClickHandler}
                  >
                    <Radio
                      size="small"
                      className="radio-input"
                      value={'Inativo'}
                      color={'darkGenoaBlue'}
                      checked={addFilters['status'] === 'Inativo'}
                      // checked={showTitulares}
                    />
                    <label>Inativo</label>
                  </div>
                </div>
              </div>
            </ThemeProvider>
          </div>
        </div>
        <div className="planos-contratos-container">
          <div className="planos-contratos-content">
            <label className="title-label">Contratos</label>
            {renderContratoPlanosOptions()}
          </div>
          <div>
            <DownloadPlansInformation plansInformation={beneficiariosFilter} />
          </div>
        </div>
      </div>
    </SearchBeneficiarios>
  );
};

export default Filters;
