import Popper from '@material-ui/core/Popper';
import Box from '@mui/material/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import DatePicker from 'react-datepicker';
import Button from 'components/DS/Button';
import { Controller } from 'react-hook-form';

const FilterTicketsCompanies = ({
  anchorElFilter,
  handleClose,
  filterCountBy,
  setFilterCountBy,
  filterOrder,
  setFilterOrder,
  ticketStatusFilter,
  dateFilterValue,
  errors,
  control,
  ticketStatusChangeHandler,
  dateFilterChangeHandler,
  clickFilterHandler
}) => {
  return (
    <Popper
      id="id"
      open={Boolean(anchorElFilter)}
      anchorEl={anchorElFilter}
      className="popper"
      placement="right"
      transition
      disablePortal
    >
      <Box>
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={3} className="paper-popup-filter-change">
            <h4 className="paper-popup-filter-change__h4">Filtrar por:</h4>
            <div className="select-ticket-tipo-container">
              <select
                className="select-ticket-tipo-container__select"
                onChange={(e) => {
                  setFilterCountBy(e.target.value);
                }}
                value={filterCountBy}
              >
                <option value="total">Total</option>
                <option value="outras demandas">Outras Demandas</option>
                <option value="movimentações">Movimentações</option>
              </select>
            </div>
            <div className="order-checkbox-container">
              <FormControl>
                <RadioGroup
                  className="checkbox-group-container"
                  onChange={(e) => {
                    setFilterOrder(e.target.value);
                  }}
                  value={filterOrder}
                >
                  <FormControlLabel
                    value="DESC"
                    control={
                      <Radio
                        className="checkbox-group-container__radio-checkbox"
                        size="medium"
                      />
                    }
                    label="DESC"
                  />
                  <FormControlLabel
                    value="ASC"
                    control={
                      <Radio
                        className="checkbox-group-container__radio-checkbox"
                        size="medium"
                      />
                    }
                    label="ASC"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <hr className="divider" />
            </div>
            <div className="data-checkbox-container">
              <FormControl>
                <RadioGroup
                  className="checkbox-data-group-container"
                  onChange={ticketStatusChangeHandler}
                  value={ticketStatusFilter}
                >
                  <FormControlLabel
                    value="criados"
                    control={
                      <Radio
                        className="checkbox-data-group-container__radio-checkbox"
                        size="medium"
                      />
                    }
                    label="Criados"
                  />
                  <FormControlLabel
                    value="resolvidos"
                    control={
                      <Radio
                        className="checkbox-data-group-container__radio-checkbox"
                        size="medium"
                      />
                    }
                    label="Resolvidos"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div>
              <h4 className="data-header">Data:</h4>
            </div>
            <div className="data-checkbox-container">
              <FormControl>
                <RadioGroup
                  className="checkbox-data-group-container"
                  onChange={dateFilterChangeHandler}
                  value={dateFilterValue}
                >
                  <FormControlLabel
                    value="desde inicio"
                    control={
                      <Radio
                        className="checkbox-data-group-container__radio-checkbox"
                        size="medium"
                      />
                    }
                    label="Desde o Início"
                  />
                  <FormControlLabel
                    value="ultima semana"
                    control={
                      <Radio
                        className="checkbox-data-group-container__radio-checkbox"
                        size="medium"
                      />
                    }
                    label="Última Semana"
                  />
                  <FormControlLabel
                    value="ultimo mes"
                    control={
                      <Radio
                        className="checkbox-data-group-container__radio-checkbox"
                        size="medium"
                      />
                    }
                    label="Último Mês"
                  />
                  <FormControlLabel
                    value="data personalizada"
                    control={
                      <Radio
                        className="checkbox-data-group-container__radio-checkbox"
                        size="medium"
                      />
                    }
                    label="Data Personalizada"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="datepicker-box-space">
              <div
                className={
                  dateFilterValue === 'data personalizada'
                    ? 'datepicker-group-container'
                    : 'datepicker-group-container-hidden'
                }
              >
                <div className="datepicker-selector-container">
                  <div>
                    <span>Data Inicial:</span>
                  </div>
                  {errors.initial_date && (
                    <span className="errorsMsg">Campo obrigatório</span>
                  )}
                  <Controller
                    name="initial_date"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        className="input-data"
                        selected={value}
                        locale="ptBR"
                        onChange={onChange}
                        placeholderText="..."
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />
                </div>
                <div className="datepicker-selector-container">
                  <div>
                    <span>Data Final:</span>
                  </div>
                  {errors.final_date && (
                    <span className="errorsMsg">Campo obrigatório</span>
                  )}
                  <Controller
                    name="final_date"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <DatePicker
                        className="input-data"
                        selected={value}
                        locale="ptBR"
                        onChange={onChange}
                        placeholderText="..."
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="date-filter-send-btn">
              <Button
                variant="primary"
                size="small"
                onClick={clickFilterHandler}
              >
                Filtrar
              </Button>
            </div>
          </Paper>
        </ClickAwayListener>
      </Box>
    </Popper>
  );
};

export default FilterTicketsCompanies;
