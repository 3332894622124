import { useUser } from 'contexts/user';
import Menu from '../HeaderMenuCorretor';
import './styles.scss';

const Header = ({ title }) => {
  const { user } = useUser();
  return (
    <div className="header">
      <div>
        <h2 className="header__title">{title}</h2>
      </div>
      <div className="header__menu">
        <Menu user={user} />
      </div>
    </div>
  );
};

export default Header;
