import styled from "styled-components";
import colors from "apps/broker/styles/colors";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2px;
  padding-left: 45px;
  padding-right: 45px;
  background: ${colors.white};
  .search-input {
    width: 80%;
    max-width: 400px;
    display: flex;
    text-align: center;
    border: none;
    border: 3px solid #bfbfbf;
    border-radius: 30px;
  }
  /* .new-ticket {
        border: none;
        background: #62A0E2;
        max-width: 200px;
        width: 100%;
        font-size: 1.125rem;
        color: #fff;
        border-radius: 30px;
        font-weight: 600;
    } */

  .menu-top {
    display: flex;
    align-items: center;
    margin-top: 10px;
    height: 49.6px;
  }

  .iniciais-empresa {
    /* background: #62A0E2;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        box-sizing: border-box;
        width: 44px;
        height: 44px; */
    span {
      color: ${colors.genoaDarkBlue};
      font-family: "Avenir Next GEO W05 Demi";
    }
  }

  .logo {
    max-width: 170px;
    max-height: 60px;
    img {
      max-width: 170px;
      max-height: 60px;
    }
  }

  .welcome-name-title-container {
    margin-top: 5px;
    display: flex;
    align-items: center;
  }

  .heavy-title {
    font-family: "Avenir Next GEO W05 Heavy";
    font-size: 20px;
  }

  .vertical-points-button {
    margin-right: 15px;
    margin-left: 5px;
    height: 24px;
    width: 24px;
    padding: 5px;
    color: #a1a1a1;

    :hover {
      cursor: pointer;
      padding: 5px;
      background-color: ${colors.genoaLightBackgroundGrey};
      border-radius: 17px;
    }
  }

  .welcome-name-title {
    font-size: 20px;
    font-family: "Avenir Next GEO W05 Regular";
    color: ${colors.genoaDarkBlue};
  }
`;
