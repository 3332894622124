import { useState, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useFilters } from 'contexts/filters';
import { BsFilter } from 'react-icons/bs';
import services from 'apps/broker/services';
import FilterTicketsCompanies from './FilterPopper';
import './style.scss';

const TicketsByCompanies = () => {
  const [anchorElFilter, setanchorElFilterPopover] = useState(null);
  const [ticketStatusFilter, setTicketStatusFilter] = useState('criados');
  const [estipulantesTickets, setEstipulantesTickets] = useState([]);
  const [dateFilterValue, setDateFilterValue] = useState('desde inicio');
  const [filterOrder, setFilterOrder] = useState('DESC');
  const [filterCountBy, setFilterCountBy] = useState('total');
  const stateRef = useRef();

  const filters = useFilters();
  const history = useHistory();

  const {
    control,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: {
      initial_date: '',
      final_date: ''
    }
  });

  stateRef.current = filterOrder;
  stateRef.current = filterCountBy;

  const clickChangeFilterHandler = ({ target }) => {
    setanchorElFilterPopover((prevState) => (prevState ? null : target));
  };

  const dateFilterChangeHandler = ({ target }) => {
    setDateFilterValue(target.value);
  };

  const ticketStatusChangeHandler = ({ target }) => {
    setTicketStatusFilter(target.value);
  };

  const clickFilterHandler = () => {
    getTicketsByEstipulante();
  };

  const orderCountDecrescently = (ticketsCount) => {
    switch (filterCountBy) {
      case 'outras demandas':
        return ticketsCount.sort(
          (a, b) => b.outrasDemandasCount - a.outrasDemandasCount
        );

      case 'movimentações':
        return ticketsCount.sort(
          (a, b) => b.movimentacaoCount - a.movimentacaoCount
        );

      default:
        return ticketsCount.sort((a, b) => b.total - a.total);
    }
  };

  const orderCountAscendently = (ticketsCount) => {
    switch (filterCountBy) {
      case 'outras demandas':
        return ticketsCount.sort(
          (a, b) => a.outrasDemandasCount - b.outrasDemandasCount
        );

      case 'movimentações':
        return ticketsCount.sort(
          (a, b) => a.movimentacaoCount - b.movimentacaoCount
        );

      default:
        return ticketsCount.sort((a, b) => a.total - b.total);
    }
  };

  const returnValueByType = (total, movimentacaoCount, outrasDemandasCount) => {
    switch (filterCountBy) {
      case 'outras demandas':
        return outrasDemandasCount;

      case 'movimentações':
        return movimentacaoCount;

      default:
        return total;
    }
  };

  const redirectToTicketsManagement = (estipulante) => {
    const filtersToDispatch = {
      redirected: true,
      custom_dates: {
        initial_date: getValues('initial_date'),
        final_date: getValues('final_date')
      },
      date_filter: dateFilterValue,
      ticket_status: ticketStatusFilter,
      estipulante,
      ticket_type: filterCountBy,
      corretor: 'all'
    };

    filters.updateHomeFilters(filtersToDispatch);

    history.push('/corretor/gestao-tickets');
  };

  const renderEstipulantesTickets = () => {
    const newOrder =
      filterOrder === 'DESC'
        ? orderCountDecrescently(estipulantesTickets)
        : orderCountAscendently(estipulantesTickets);

    return newOrder?.map(
      (
        { razao_social, total, movimentacaoCount, outrasDemandasCount },
        index
      ) =>
        razao_social && (
          <tr key={index} className="tickets-companies-table__tbody-tr">
            <td
              className="tickets-companies-table__tbody-td"
              onClick={() => redirectToTicketsManagement(razao_social)}
            >
              {razao_social}
            </td>
            <td
              className="tickets-companies-table__tbody-td"
              onClick={() => redirectToTicketsManagement(razao_social)}
            >
              {returnValueByType(total, movimentacaoCount, outrasDemandasCount)}
            </td>
            <td></td>
          </tr>
        )
    );
  };

  const handleClose = () => {
    setanchorElFilterPopover(null);
  };

  const getTicketsByEstipulante = async () => {
    const ticketStatus = JSON.stringify(ticketStatusFilter);
    const customDates = JSON.stringify(getValues());

    const response = await services.tickets.getAllByCompanies(
      dateFilterValue,
      ticketStatus,
      customDates
    );

    if (response.status === 200) {
      setEstipulantesTickets(response.data.ticketsCount);
    }
  };

  useEffect(() => {
    getTicketsByEstipulante();
  }, []);

  return (
    <div className="tickets-companies">
      <div>
        <span className="tickets-companies__title">Tickets por empresa</span>
      </div>
      <div>
        <table className="tickets-companies-table">
          <thead className="tickets-companies-table__theader">
            <tr className="tickets-companies-table__theader-tr">
              <th className="tickets-companies-table__theader-th">
                Estipulante
              </th>
              <th>Tickets</th>
              <th>
                <BsFilter onClick={clickChangeFilterHandler} />
                <FilterTicketsCompanies
                  anchorElFilter={anchorElFilter}
                  handleClose={handleClose}
                  filterCountBy={filterCountBy}
                  setFilterCountBy={setFilterCountBy}
                  filterOrder={filterOrder}
                  setFilterOrder={setFilterOrder}
                  ticketStatusFilter={ticketStatusFilter}
                  dateFilterValue={dateFilterValue}
                  errors={errors}
                  control={control}
                  ticketStatusChangeHandler={ticketStatusChangeHandler}
                  dateFilterChangeHandler={dateFilterChangeHandler}
                  clickFilterHandler={clickFilterHandler}
                />
              </th>
            </tr>
          </thead>
          <tbody className="tickets-companies-table__tbody">
            {renderEstipulantesTickets()}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TicketsByCompanies;
