import zecaLogo from '../../../../../../assets/imgs/zeca_image_home.jpg';
import { GoDotFill } from 'react-icons/go';
import './style.scss';

const CareOverview = ({ data, companiesCount }) => {
  return (
    <div className="care-overview">
      <div>
        <span className="care-overview__title">
          Pessoas cuidadas pela Genoa
        </span>
      </div>
      <div className="care-overview-painel">
        <div>
          <div className="care-overview-legend-container">
            <div className="care-overview-legend">
              <div className="care-overview-legend__label-content">
                <GoDotFill className="care-overview-legend__dot-health" />
                <div className="care-overview-legend__label">Saúde</div>
              </div>
              <div className="care-overview-legend__data">{data.saude}</div>
            </div>

            <div className="care-overview-legend">
              <div className="care-overview-legend__label-content">
                <GoDotFill className="care-overview-legend__dot-company" />
                <div className="care-overview-legend__label">Estipulantes</div>
              </div>
              <div className="care-overview-legend__data">{companiesCount}</div>
            </div>
          </div>
        </div>
        <div>
          <img className="care-overview-painel__image" src={zecaLogo} alt="logo Zeca" />
        </div>
      </div>
    </div>
  );
};

export default CareOverview;
