import { useState, useEffect } from 'react';
import Header from 'apps/broker/components/Header';
import SidemenuRh from 'apps/broker/components/Sidebar';
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { CircularProgress } from '@material-ui/core';
import services from 'apps/broker/services';
import { useUser } from 'contexts/user';
import { useFilters } from 'contexts/filters';
import DemandsSummary from './components/DemandsSummary';
import CareOverview from './components/CareOverview';
import MyActivities from './components/MyActivities';
import DemandsByUser from './components/DemandsByUser';
import TicketsByCompanies from './components/TicketsByCompanies';
import './style.scss';

const HomeCorretor = () => {
  const { user } = useUser();
  const filters = useFilters();

  const [data, setData] = useState({});
  const [demandasUserLogado, setDemandasUserLogado] = useState({});
  const [countDUserLogado, setCountDUserLogado] = useState({});
  const [vencimento, setVencimento] = useState('vencidas');
  const [estipulantesCount, setEstipulantesCount] = useState(0);
  const [loading, setLoading] = useState(false);

  registerLocale('ptBR', ptBR);

  const getData = async () => {
    setLoading(true);
    const requestCountResponse = await services.user.getRequestsCount();
    setData(requestCountResponse.data);

    const companiesCountResponse = await services.policyOwner.getAll();
    setEstipulantesCount(companiesCountResponse.data.length);

    setLoading(false);
  };

  const getDemandasPorUsuarioLogado = async () => {
    const userId = user.usuario_id;

    const response = await services.user.getRequestsTologinUser(userId);

    if (response.status === 200) {
      setDemandasUserLogado(response.data);
    }
  };

  const filterAndCountDemandasUserLogado = (demandasUserLogado) => {
    const timeStampInicial = new Date().setHours(0, 0, 0, 0);

    const timeStampFinal = new Date().setHours(23, 59, 0, 0);

    const timeStampMais1Dia =
      new Date(timeStampInicial).getTime() + 1 * 24 * 60 * 60 * 1000;

    if (vencimento === 'vencidas') {
      const ticket = demandasUserLogado.tickets?.filter(
        (item) => new Date(item.data_atuacao).getTime() < timeStampInicial
      );
      const mov = demandasUserLogado.movimentacao?.filter(
        (item) => new Date(item.data_atuacao).getTime() < timeStampInicial
      );
      const atividade = demandasUserLogado.atividades?.filter(
        (item) => new Date(item.data_agendada).getTime() < timeStampInicial
      );
      const task = demandasUserLogado.tasks?.filter(
        (item) => new Date(item.data_task).getTime() < timeStampInicial
      );

      setCountDUserLogado({
        ticket,
        mov,
        atividade,
        task
      });
    }

    if (vencimento === 'hoje') {
      const ticket = demandasUserLogado.tickets?.filter(
        (item) =>
          new Date(item.data_atuacao).getTime() >= timeStampInicial &&
          new Date(item.data_atuacao).getTime() < timeStampFinal
      );
      const mov = demandasUserLogado.movimentacao?.filter(
        (item) =>
          new Date(item.data_atuacao).getTime() >= timeStampInicial &&
          new Date(item.data_atuacao).getTime() < timeStampFinal
      );
      const atividade = demandasUserLogado.atividades?.filter(
        (item) =>
          new Date(item.data_agendada).getTime() >= timeStampInicial &&
          new Date(item.data_agendada).getTime() < timeStampFinal
      );
      const task = demandasUserLogado.tasks?.filter(
        (item) =>
          new Date(item.data_task).getTime() >= timeStampInicial &&
          new Date(item.data_task).getTime() <= timeStampFinal
      );

      setCountDUserLogado({
        ticket,
        mov,
        atividade,
        task
      });
    }

    if (vencimento === 'amanha') {
      const ticket = demandasUserLogado.tickets?.filter(
        (item) => new Date(item.data_atuacao).getTime() === timeStampMais1Dia
      );
      const mov = demandasUserLogado.movimentacao?.filter(
        (item) => new Date(item.data_atuacao).getTime() === timeStampMais1Dia
      );
      const atividade = demandasUserLogado.atividades?.filter(
        (item) => new Date(item.data_agendada).getTime() === timeStampMais1Dia
      );
      const task = demandasUserLogado.tasks?.filter(
        (item) => new Date(item.data_task).getTime() === timeStampMais1Dia
      );

      setCountDUserLogado({
        ticket,
        mov,
        atividade,
        task
      });
    }
  };

  useEffect(() => {
    getDemandasPorUsuarioLogado();
  }, []);

  useEffect(() => {
    filterAndCountDemandasUserLogado(demandasUserLogado);
  }, [vencimento, demandasUserLogado]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="broker-home">
      <SidemenuRh />
      <div className="broker-home-container">
        <Header />
        {!loading ? (
          <div className="broker-home-main">
            <div className="broker-home-main-content">
              <div className="broker-home-main-content__column-one">
                <DemandsSummary data={data} />
              </div>

              <div className="broker-home-main-content__column-two">
                <MyActivities
                  setVencimento={setVencimento}
                  vencimento={vencimento}
                  user={user}
                  filters={filters}
                  countDUserLogado={countDUserLogado}
                />
                <TicketsByCompanies />
                <CareOverview data={data} companiesCount={estipulantesCount} />
              </div>
              <div className="broker-home-main-content__column-three">
                <DemandsByUser />
              </div>
            </div>
          </div>
        ) : (
          <div className="circular-spinner-container">
            <CircularProgress
              className="circular-spinner"
              size={120}
              thickness={2.5}
            />
            <h3 className="circular-spinner-container__h3">Carregando...</h3>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeCorretor;
