import http, { BROKERAGE_UUID } from 'services/http';

const getInsuranceCompanyByType = async (type) => {
  const res = await http.get('/corretor/add/get-seguradora', {
    headers: {
      tipo: type
    }
  });

  return res;
};

// excluir essa rota depois da refatoração do backend
const getAllInsurance = async () => {
  const res = await http.get('/corretor/add/get-seguradora');

  return res;
};

const getAll = async () => {
  const res = await http.get('/corretor/seguradoras');

  return res;
};

// Estipulantes Dados Gerais
const createContractExpireDate = async (data) => {
  const res = await http.post('/corretor/update/contrato/expireDate', data);

  return res;
};

const updateReopenContractDate = async (data) => {
  const res = await http.post(
    '/corretor/update/contrato/reopenContractDate',
    data
  );

  return res;
};

// DocumentosInner
// Esta função é usada duas vezes para forms diferentes no mesmo componente
// Falta achar o delete
const createDocumentByUUID = async (data, formData, type) => {
  const res = await http.post('/corretor/file-contrato', formData, {
    headers: {
      contrato: data.contrato,
      corretora: BROKERAGE_UUID,
      type
    }
  });

  return res;
};

// ReajusteInner
const getAdjustmentsById = async (companyId) => {
  const res = await http.get('/corretor/contrato/reajustes', {
    headers: {
      estipulante: companyId
    }
  });

  return res;
};

const createAdjustments = async (data) => {
  const res = await http.post('/corretor/contrato/reajustes', data);

  return res;
};

const deleteAdjustmentsByContractId = async (reajuste) => {
  const res = await http.delete('/corretor/contrato/reajustes', {
    headers: {
      contrato: reajuste['contrato_id'],
      ano: reajuste['ano']
    }
  });

  return res;
};

// VidasInner
const getPlansByContractIdref = async (contratoIdref) => {
  const res = await http.get('/corretor/get-planos', {
    headers: {
      contrato: contratoIdref,
      corretora: BROKERAGE_UUID
    }
  });

  return res;
};

// SeguroOdontoAtualizacao
const updateContractInfoByContractIdref = async (
  productId,
  contractIdref,
  companyUUID,
  confirm,
  data
) => {
  const res = await http.post('/corretor/update/contrato', data, {
    headers: {
      produto: productId,
      contrato: contractIdref,
      estipulante: companyUUID,
      corretora: BROKERAGE_UUID,
      confirma: confirm
    }
  });

  return res;
};

const updateDisablePlanByContractId = async (data) => {
  const res = await http.post(
    '/corretor/update/contrato/plano/deactivate',
    data
  );

  return res;
};

const updateEnablePlanByContractId = async (data) => {
  const res = await http.post('/corretor/update/contrato/plano/activate', data);

  return res;
};

const removePlanByContractId = async (data) => {
  const res = await http.post('/corretor/update/contrato/plano/remove', data);

  return res;
};

const getCommissionContractId = async (companyId, contractId) => {
  const res = await http.get('/corretor/contrato/comissionamento', {
    headers: {
      estipulante: companyId,
      contrato: contractId
    }
  });

  return res;
};

const createCommissioning = async (data) => {
  const res = await http.post('/corretor/contrato/comissionamento', data);

  return res;
};

const updateQuestionsAndAnswersByContractId = async (
  questionId,
  contractId,
  data
) => {
  const res = await http.put('/corretor/perguntas', data, {
    headers: {
      id: questionId,
      contrato: contractId
    }
  });

  return res;
};

const createQuestionsAndAnswers = async (data) => {
  const res = await http.post('/corretor/contrato/perguntas-respostas', data);

  return res;
};

const getQuestionsAndAnswers = async (contractIdref) => {
  const res = await http.get('/corretor/perguntas', {
    headers: {
      contrato: contractIdref
    }
  });

  return res;
};

const deleteQuestionsAndAnswers = async (questionId, contractId) => {
  const res = await http.delete('/corretor/perguntas', {
    headers: {
      id: questionId,
      contrato: contractId
    }
  });

  return res;
};

const createContactInformation = async (data) => {
  const res = await http.post('/corretor/seguradoras/adicionar-contato', {
    newContato: data.newContact
  });

  return res;
};

const updateContactInformation = async (data) => {
  const res = await http.put('/corretor/seguradoras/adicionar-contato', data);

  return res;
};

const deleteContactInformation = async (contato) => {
  const res = await http.delete('/corretor/seguradoras/remover-contato', {
    data: {
      contato
    }
  });

  return res;
};

const createNewContractByBusinessId = async (formData) => {
  const res = await http.post('/corretor/contrato-novo', formData);

  return res;
};

const createAdjustmentByBusinessId = async (data) => {
  const res = await http.post('/corretor/contrato-novo/reajuste', data);

  return res;
};

const updateContractCancelByBusinessId = async (data) => {
  const res = await http.put('/corretor/contrato/cancelar', data);

  return res;
};

const getListContracts = async (selectEstipulante) => {
  const res = await http.get(`/corretor/lista-contratos/${selectEstipulante}`);

  return res;
};

const updateInsuranceByTicketId = async (data) => {
  const res = await http.post('/corretor/tickets/seguradora', data);

  return res;
};

const modules = {
  getInsuranceCompanyByType,
  getAllInsurance,
  getAll,
  createContractExpireDate,
  updateReopenContractDate,
  createDocumentByUUID,
  getAdjustmentsById,
  createAdjustments,
  deleteAdjustmentsByContractId,
  getPlansByContractIdref,
  updateContractInfoByContractIdref,
  updateDisablePlanByContractId,
  updateEnablePlanByContractId,
  updateContactInformation,
  removePlanByContractId,
  getCommissionContractId,
  createCommissioning,
  updateQuestionsAndAnswersByContractId,
  createQuestionsAndAnswers,
  getQuestionsAndAnswers,
  deleteQuestionsAndAnswers,
  createContactInformation,
  deleteContactInformation,
  createNewContractByBusinessId,
  createAdjustmentByBusinessId,
  updateContractCancelByBusinessId,
  getListContracts,
  updateInsuranceByTicketId
};

export default modules;
