import { useState, useEffect } from 'react';
import { GiHealthNormal } from 'react-icons/gi';
import { FaTooth } from 'react-icons/fa';
import { TbEyeglass } from 'react-icons/tb';
import { AiOutlineDollar } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import Button from 'components/DS/Button';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import PolicyOwnerDetails from '../PolicyOwnerDetails';

const TableBody = ({
  contracts = [],
  brokerInformation,
  onFetchPolicyOwners,
  policyOwnerId
}) => {
  const history = useHistory();

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [contractSelected, setContractSelected] = useState(null);
  const [contractId, setContractId] = useState('');

  const handleData = (contratoId) => {
    setContractId(contratoId);
  };

  const onFetchContract = async () => {
    const policyOwnersUpdated = await onFetchPolicyOwners();

    const newContractSelected = policyOwnersUpdated.contracts.find(
      (contract) => contract.id === contractSelected.id
    );

    setContractSelected(newContractSelected);
  };

  useEffect(() => {
    const contrato_id = contractId;
    const estipulante_id = policyOwnerId;
    history.push(`/corretor/estipulantes/${estipulante_id}/contratos/${contrato_id}`);
  }, [contractId]);

  return (
    <div>
      {contracts.map((contract) => (
        <div className="table-contracts__body-list">
          <div></div>
          <div className="table-contracts__body-item--icon-product">
            <span>
              {contract.type === 'saude' ? (
                <GiHealthNormal size={19} />
              ) : (
                <FaTooth size={19} />
              )}
            </span>
          </div>
          <div className="table-contracts__body-item">
            <span>{contract.lifesInsuranceTotal}</span>
          </div>
          <div className="table-contracts__body-item">
            <span>{contract.nome}</span>
          </div>
          <div className="table-contracts__body-item">
            <span>{contract.numero_apolice}</span>
          </div>
          <div className="table-contracts__body-item">
            <span>{contract.mes_aniversario}</span>
          </div>
          <div className="table-contracts__body-item--button">
            <Button
              variant="transparent"
              onClick={() => handleData(contract.id)}
            >
              <AiOutlineDollar size={20} />
            </Button>
            <Button
              variant="transparent"
              onClick={() => {
                setContractSelected(contract);
                setIsOpenDrawer(!isOpenDrawer);
              }}
            >
              <TbEyeglass size={20} />
            </Button>
          </div>
          <div></div>
        </div>
      ))}

      <SwipeableDrawer
        anchor="right"
        open={isOpenDrawer}
        onClose={() => setIsOpenDrawer(!isOpenDrawer)}
        onOpen={() => setIsOpenDrawer(!isOpenDrawer)}
      >
        <PolicyOwnerDetails
          onClose={() => setIsOpenDrawer(!isOpenDrawer)}
          brokerInformation={brokerInformation}
          contract={contractSelected}
          onFetchContract={onFetchContract}
        />
      </SwipeableDrawer>
    </div>
  );
};

export default TableBody;
