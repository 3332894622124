import styled from 'styled-components'
import colors from 'apps/broker/styles/colors'

export const SearchBeneficiarios = styled.div`
  font-family: Avenir Next GEO W05 Demi;
  display: grid;
  grid-template-columns: 350px 1fr;
  column-gap: 30px;
  margin-bottom: 15px;
  height: 180px;
  width: max-content;

  #status-label {
    // margin-left: 9px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
  }

  .edit-buttons-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: end;
    .button-edit,
    .delete-button {
      margin-bottom: 7px;
    }
  }

  .selectbox-checkbox-options {
    display: flex;
    flex-direction: column;

    margin-bottom: 20px;

    select {
      font-family: 'Avenir Next GEO W05 Regular';
      padding: 10px;
      width: 307px;
      border-radius: 8px;
      border: 1px ${colors.genoaBlue} solid;
      margin-top: 12px;

      :focus-visible {
        outline: 1px ${colors.genoaBlue}f5 auto;
      }
    }
  }

  .paper-subestipulante-checkboxes {
    width: 402px;
    height: 80px;
    overflow-y: auto;
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    white-space: nowrap;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;

    ::-webkit-scrollbar {
      width: 4px;
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #grey;
      box-shadow: none;
      border-radius: 14px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 2px grey;
      border-radius: 14px;
    }

    .paper-container {
      padding-top: 5px;
    }

    .checkbox-option {
      padding: 0px;
      padding-right: 5px;
    }

    .option-span-container {
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 2.5px;
      padding-top: 2.5px;
      max-width: 370px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .option-span {
      font-family: 'Avenir Next GEO W05 Regular';
      font-size: 0.8rem;
      max-width: 320px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .selectbox {
    .title-label {
      margin-left: 10px;
    }

    .selectbox-first-row-container {
      display: grid;
      // grid-template-columns: 760px 210px;
      column-gap: 20px;
    }

    .planos-contratos-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .planos-contratos-content {
      margin-top: 25px;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
    }

    display: flex;
    flex-direction: column;

    .status-options-container {
      display: flex;
      gap: 15px;
      margin-top: 20px;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
    }

    .checkbox-container {
      font-size: 0.8rem;
      line-height: 13px;

      .radio-input-container {
        display: flex;
        align-items: center;
        font-size: 0.7rem;

        span {
          padding: 0;
        }
      }

      label {
        margin-left: 5px;
        font-family: 'Avenir Next GEO W05 Regular';
        height: max-content;
      }
    }

    .select-icon {
      margin-left: -30px;
      transform: translateY(3px);
      color: ${colors.genoaBlue};
    }

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-family: 'Avenir Next GEO W05 Regular';
      padding: 10px;
      padding-left: 20px;
      padding-right: 35px;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.5 9L0.73686 0.75L10.2631 0.75L5.5 9Z' fill='%23456AED'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position-x: 96%;
      background-position-y: 12px;
      width: 350px;
      border-radius: 20px;
      border: none;
      font-size: 0.7rem;
      margin-top: 12px;
      :focus-visible {
        outline: 1px ${colors.genoaBlue}f5 auto;
      }
    }
  }

  .buttonSearch {
    display: flex;
    align-items: center;

    .searchFilter {
      display: flex;
      align-items: center;
      background: transparent;
      color: ${colors.genoaBlue};

      padding: 0.5rem;
      border-radius: 100%;
      border: 1px solid ${colors.genoaBlue};

      font-size: 1.5rem;
      cursor: pointer;

      transition: all 0.2s;

      &:hover {
        background: ${colors.genoaBlue};
        color: ${colors.white};
      }
    }
  }

  .button-edit {
    display: flex;
    align-items: center;
    background-color: ${colors.genoaGrey};
    border: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    color: ${colors.white};
    justify-content: center;
    &:hover {
      cursor: pointer;
    }
  }

  .button-edit > span {
    margin-right: 10px;
  }

  .delete-button {
    margin-left: 30px;
    color: ${colors.genoaDarkBlue};
    &:hover {
      cursor: pointer;
    }
  }

  .paper-popup-delete-confirmation {
    width: 500px;
    height: 80px;
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .popup-options-container {
    margin-top: 5px;
    font-family: 'Avenir Next GEO W05 Regular';
  }

  .popup-message {
    font-weight: bold;
  }

  .popup-options {
    margin-left: 10px;
    margin-right: 10px;
    align-itens: center;

    &:hover {
      font-weight: bold;
      cursor: pointer;
    }
  }

  .check-icon {
    margin-right: 2px;
    color: green;
  }

  .cross-icon {
    margin-right: 2px;
    color: red;
  }

  .inputSearch {
    margin-top: 30px;
    .search {
      display: flex;
      flex-direction: column;

      .input-button {
        display: flex;

        align-items: center;

        input {
          font-family: 'Avenir Next GEO W05 Regular';
          padding: 10px;
          padding-left: 20px;
          background: white;
          width: 350px;
          border-radius: 20px;
          border: none;
          margin-top: 12px;
          font-size: 0.8rem;

          :focus-visible {
            outline: 1px ${colors.genoaBlue}f5 auto;
          }
        }
        input.error {
          border: 1px #ff5959 solid;
        }
        .btn-search {
          margin-left: 10px;
          margin-top: 10px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          height: fit-content;
          padding: 8px;
          background: ${colors.genoaBlue};
          color: #fff;
          border: none;
          border-radius: 50%;
          border: 2px ${colors.genoaBlue} solid;

          :hover {
            border: 2px #ba97f7 solid;
          }
        }
      }
    }
  }
  .inputStatus {
    display: flex;
    flex-direction: column;

    select {
      font-family: 'Avenir Next GEO W05 Regular';
      padding: 10px;
      width: 100%;
      border-radius: 8px;
      border: 1px ${colors.genoaBlue} solid;
      margin-top: 12px;

      :focus-visible {
        outline: 1px ${colors.genoaBlue}f5 auto;
      }
    }
  }

  .first-row-container {
    height: 180px;
  }
`
